import React from "react";
import FormStation from "./FormStation";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import ModalPdf from "../PageCompany/ModalPdf";

const getStationsQuery = gql`
  {
    companies {
      id
      name
    }
    sites {
      id
      description
    }
    towerTypes {
      id
      name
    }
    stationTypes {
      id
      name
      type
    }
  }
`;

const StationModal = ({
  station,
  handleChange,
  handleSectorChange,
  handleRemoveClick,
  handleAddClick,
  closeModal,
  saveItem,
  setFieldValue,
}) => {
  const { data } = useQuery(getStationsQuery);
  return (
    <div
      className="modal fade"
      id="stationModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-lg"
        role="document"
      >
        <div className="modal-content">
          {/* Header Row */}
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              Station
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeModal}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {/* Body */}
          <div className="modal-body">
            {/* Form */}
            <FormStation
              station={station}
              companies={data?.companies}
              sites={data?.sites}
              towerTypes={data?.towerTypes}
              stationTypes={data?.stationTypes}
              handleChange={handleChange}
              handleSectorChange={handleSectorChange}
              handleRemoveClick={handleRemoveClick}
              handleAddClick={handleAddClick}
              closeModal={closeModal}
              saveItem={saveItem}
              setFieldValue={setFieldValue}
            />
          </div>
          {/* Button Row */}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
            
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={saveItem}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationModal;
