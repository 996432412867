"use client";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { Spinner, progressWidths } from "./shared";
import ModalPdf from "../PageCompany/ModalPdf";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function base64ToBlob(base64WithContentType) {
  const [contentTypeInfo, base64Data] = base64WithContentType.split(",");
  const contentType = contentTypeInfo.match(/:(.*?);/)[1];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

export const FileUploader = ({
  setFieldValue,
  name,
  multipleUpload = false,
  fileObjects,
}) => {
  const [progress, setProgress] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  console.log(fileObjects);
  console.log(files);

  const onUpload = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    setIsLoading(true);
    if (file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFieldValue(name, event.target.result);
      };
      setIsLoading(false);
      return reader.readAsDataURL(file);
    } else {
      imageCompression(file, options).then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          setFieldValue(name, event.target.result);
        };
        setIsLoading(false);
        return reader.readAsDataURL(compressedFile);
      });
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log(acceptedFiles);
      if (acceptedFiles?.length) {
        const updatedFiles = acceptedFiles.map((file) => {
          return Object.assign(file, { preview: URL.createObjectURL(file) });
        });
        console.log(updatedFiles);
        if (multipleUpload) {
          setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
        } else {
          setFiles(updatedFiles);
        }

        const newFileValues = await Promise.all(
          acceptedFiles.map(async (file) => {
            const result = await getBase64(file);
            return { attachment: result };
          })
        );

        console.log(newFileValues);
        setFieldValue(
          name,
          multipleUpload ? newFileValues : newFileValues[0].attachment
        );
      }
    },
    [multipleUpload, setFieldValue, name]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multipleUpload,
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
  });

  const handleRemoveFile = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  return (
    
    <div
      {...getRootProps()}
      className={`mt-2 flex flex-col items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 text-center ${
        isDragActive ? "bg-blue-600/10" : ""
      }`}
    >
      {files.length > 0 ? (
        files.map((file, index) => (
          <div key={index} className="mb-4">
            {file.type === "application/pdf" ? (
              <iframe src={file.preview} height="100%" width="100%"></iframe>
            ) : (
              <img
                src={file.preview}
                alt="preview"
                style={{ objectFit: "contain", height: "130px" }}
              />
            )}
            <button
              className={`relative mt-4 p-2 flex w-full items-center justify-center overflow-hidden rounded-md text-white after:transition-[width] after:duration-500 ${
                isLoading
                  ? `bg-blue-400 after:absolute after:left-0 after:h-full after:bg-blue-600 ${
                      progressWidths[progress[file.name]]
                    }`
                  : "bg-blue-600"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveFile(file);
              }}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : `Remove ${file.name}`}
            </button>
          </div>
        ))
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className={"mx-auto block h-12 w-12 align-middle text-gray-400"}
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M5.5 17a4.5 4.5 0 0 1-1.44-8.765a4.5 4.5 0 0 1 8.302-3.046a3.5 3.5 0 0 1 4.504 4.272A4 4 0 0 1 15 17H5.5Zm3.75-2.75a.75.75 0 0 0 1.5 0V9.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0l-3.25 3.5a.75.75 0 1 0 1.1 1.02l1.95-2.1v4.59Z"
              clipRule="evenodd"
            ></path>
          </svg>
          <label
            htmlFor="file-upload"
            className={
              "relative mt-4 flex w-64 cursor-pointer items-center justify-center text-sm font-semibold leading-6 text-gray-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
            }
          >
            {!isLoading ? `Choose files or drag and drop` : `Loading...`}
            <input className="sr-only" {...getInputProps()} />
          </label>
        </>
      )}
    </div>
  );
};
