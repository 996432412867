import React from "react";
import { useLocation } from "react-router-dom";

export default function OverviewPage3DInspect() {

  const location = useLocation();
  console.log({location})
  //const { id } = location.state;

  return (
    <iframe
      className="w-screen h-screen"
      // src="https://cloud.pix4d.com/embed/bim/mesh/459842?shareToken=4c18ce7b62744fd8a691551b77a9c152"
      src={"https://inspect.pix4d.com/embed/745411?shareToken=f3458cea-971a-40ef-aeeb-5266d6bc4270&origin=dashboard"}
      frameborder="0"
      allowFullscreen
    ></iframe>
  );
  // };
  // return (
  //   <div
  //     onClick={(e) => iii(link)}
  //     className=" right-7 hover:no-underline rounded top-6 cursor-pointer bg-gray-900 hover:bg-gray-100 text-white p-1"
  //   >
  //     full sCcreen
  //   </div>
  // );
}
