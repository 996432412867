import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import FormCustomSelect from "../FormCustomSelect";
import FileUploader from "../FileUploader";
import ModalPdf from "../PageCompany/ModalPdf";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  band: Yup.string().nullable().required("Band is required"),
});

export default function FormStation({
  station,
  companies,
  sites,
  towerTypes,
  stationTypes,
  handleChange,
  handleSectorChange,
  handleRemoveClick,
  handleAddClick,
  setFieldValue,
  closeModal,
  saveItem,
}) {
  console.log(station);
console.log(station?.stationPdfs?.[0]?.attachment)
  return (
    <div className="form-row w-full">
      {/* Form Row */}
      <div className="form-group col-md-12">
        <label forhtml="description" className="col-form-label">
          Facility Name:
        </label>
        <input
          type="text"
          className={
            "form-control"
            // (errors.description && touched.description ? " is-invalid" : "")
          }
          id="description"
          name="description"
          onChange={(e) => handleChange(e)}
          value={station.description}
        />
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="form-group col-md-6">
        <label forhtml="latitude" className="col-form-label">
          Latitude:
        </label>
        <input
          type="number"
          className={
            "form-control"
            // (errors.latitude && touched.latitude ? " is-invalid" : "")
          }
          id="latitude"
          name="latitude"
          onChange={(e) => handleChange(e)}
          value={station.latitude}
        />
      </div>
      <div className="form-group col-md-6">
        <label forhtml="longitude" className="col-form-label">
          Longitude:
        </label>
        <input
          type="number"
          className={
            "form-control"
            // (errors.longitude && touched.longitude ? " is-invalid" : "")
          }
          id="longitude"
          name="longitude"
          onChange={(e) => handleChange(e)}
          value={station.longitude}
        />
      </div>
      {/* End Row */}
      <div className="form-group col-md-6">
        <label forhtml="height" className="col-form-label">
          Height above sea level:
        </label>
        <input
          type="number"
          className={
            "form-control"
            // (errors.height && touched.height ? " is-invalid" : "")
          }
          id="height"
          name="height"
          onChange={(e) => handleChange(e)}
          value={station.height}
        />
      </div>

      <div className="form-group col-md-6">
        <label forhtml="sector" className="col-form-label">
          Sector:
        </label>
        {station.sectors.map((sector, index) => {
          sector.__typename && delete sector.__typename;
          return (
            <div className="form-row" key={index}>
              <div className="form-group col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id={`sector${index}`}
                  name="description"
                  onChange={(e) => handleSectorChange(e, index)}
                  value={sector?.description || ""}
                />
              </div>
              <div
                className={`form-group ${
                  station.sectors.length !== 1 ? "col-md-2" : false
                }`}
              >
                {station.sectors.length !== 1 && (
                  <button
                    className="form-control"
                    onClick={() => handleRemoveClick(index)}
                  >
                    -
                  </button>
                )}
              </div>
              <div className="form-group col-md-2">
                {station.sectors.length - 1 === index && (
                  <button className="form-control" onClick={handleAddClick}>
                    +
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-1 pb-4 w-full">
        <div
          className={`col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6`}
        >
          {/* Form Col*/}
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            {"Company"}
          </label>
          <Select
            name={"company"}
            value={{
              value: companies?.find(
                (option) => option?.id === station.company?.id
              )?.id,
              label: companies?.find(
                (option) => option?.id === station.company?.id
              )?.name,
            }}
            onChange={async (value, name) => {
              const e = {
                target: { name: name.name, value: value.value },
              };
              console.log({ e });
              handleChange(e);
            }}
            placeholder="--choose--"
            options={companies?.map((company, index) => {
              return { value: company.id, label: company.name };
            })}
            isMulti={false}
          />
        </div>
        {/* <FormCustomSelect
          id="company"
          name={`company`}
          title="Company:"
          options={companies?.map((company, index) => {
            return { value: company.id, label: company.name };
          })}
          placeholder="--choose--"
          isMulti={false}
          onChangeFunction={async (value, name) => {
            const e = { target: { name: name, value: value } };
            // console.log({ e });
            handleChange(e);
          }}
        /> */}
        {/* Form Col*/}
        <div
          className={`col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6`}
        >
          {/* Form Col*/}
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            {"Station Type"}
          </label>
          <Select
            name={"stationType"}
            value={{
              value: stationTypes?.find(
                (option) => option?.id === station.stationType?.id
              )?.id,
              label: stationTypes?.find(
                (option) => option?.id === station.stationType?.id
              )?.name,
            }}
            onChange={async (value, name) => {
              const e = {
                target: { name: name.name, value: value.value },
              };
              console.log({ e });
              handleChange(e);
            }}
            placeholder="--choose--"
            options={stationTypes?.map((type, index) => {
              return { value: type.id, label: type.name };
            })}
            isMulti={false}
          />
        </div>
        {/* <input
          id="stationType"
          name={`stationType`}
          title="Facility:"
          options={stationTypes?.map((company, index) => {
            return { value: company.id, label: company.name };
          })}
          component={FormCustomSelect}
          placeholder="--choose--"
          isMulti={false}
          onChangeFunction={async (value, name) => {
            const e = { target: { name: name, value: value } };
            // console.log({ e });
            handleChange(e);
          }}
        /> */}
      </div>
      {/* End Row */}

      {/* Form Row */}
      {station.stationType?.id !== "2004" && (
        <div className="grid grid-cols-12 gap-6 pt-1 pb-4 w-full">
          {/* Form Col*/}
          <div
            className={`col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6`}
          >
            {/* Form Col*/}
            <label className="block uppercase tracking-wide text-xs font-bold my-2">
              {"Site "}
            </label>
            <Select
              name={"site"}
              value={{
                value: sites?.find((option) => option?.id === station.site?.id)
                  ?.id,
                label: sites?.find((option) => option?.id === station.site?.id)
                  ?.description,
              }}
              onChange={async (value, name) => {
                const e = {
                  target: { name: name.name, value: value.value },
                };
                console.log({ e });
                handleChange(e);
              }}
              placeholder="--choose--"
              options={sites?.map((site, index) => {
                return { value: site.id, label: site.description };
              })}
              isMulti={false}
            />
          </div>
          {/* <input
            id="site"
            name={`site`}
            title="Site:"
            options={sites?.map((company, index) => {
              return {
                value: company.id,
                label: company.description,
              };
            })}
            component={FormCustomSelect}
            placeholder="--choose--"
            isMulti={false}
            onChangeFunction={async (value, name) => {
              const e = { target: { name: name, value: value } };
              // console.log({ e });
              handleChange(e);
            }}
          /> */}
          {/* Form Col*/}
          <div
            className={`col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6`}
          >
            {/* Form Col*/}
            <label className="block uppercase tracking-wide text-xs font-bold my-2">
              {"Tower Type"}
            </label>
            <Select
              name={"towerType"}
              value={{
                value: towerTypes?.find(
                  (option) => option?.id === station.towerType?.id
                )?.id,
                label: towerTypes?.find(
                  (option) => option?.id === station.towerType?.id
                )?.name,
              }}
              onChange={async (value, name) => {
                const e = {
                  target: { name: name.name, value: value.value },
                };
                console.log({ e });
                handleChange(e);
              }}
              placeholder="--choose--"
              options={towerTypes?.map((site, index) => {
                return { value: site.id, label: site.name };
              })}
              isMulti={false}
            />
          </div>
          {/* <input
            id="towerType"
            name={`towerType`}
            title="Tower Type:"
            options={towerTypes?.map((company, index) => {
              return { value: company.id, label: company.name };
            })}
            component={FormCustomSelect}
            placeholder="--choose--"
            isMulti={false}
            onChangeFunction={async (value, name) => {
              const e = { target: { name: name, value: value } };
              // console.log({ e });
              handleChange(e);
            }}
          /> */}
        </div>
      )}
      {/* End Row */}

      <div className="form-group col-md-6">
        <label forhtml="description" className="col-form-label">
          Site Status:
        </label>
        <input
          type="text"
          className="form-control"
          id="siteStatus"
          name="siteStatus"
          onChange={handleChange}
          value={station.siteStatus || ""}
        />
      </div>
      <div className="form-group col-md-6">
        <label forhtml="description" className="col-form-label">
          Site ID UMTS:
        </label>
        <input
          type="text"
          className="form-control"
          id="siteIDUMTS"
          name="siteIDUMTS"
          onChange={handleChange}
          value={station.siteIDUMTS || ""}
        />
      </div>
      <div className="form-group col-md-6">
        <label forhtml="description" className="col-form-label">
          Site ID LTE:
        </label>
        <input
          type="text"
          className="form-control"
          id="siteIDLTE"
          name="siteIDLTE"
          onChange={(e) => handleChange(e)}
          value={station.siteIDLTE || ""}
        />
      </div>
      <div className="form-group col-md-6">
        <label forhtml="remarks" className="col-form-label">
          Remarks:
        </label>
        <input
          as="textarea"
          type="text"
          className={
            "form-control"
            // (errors.remarks && touched.remarks ? " is-invalid" : "")
          }
          id="remarks"
          name="remarks"
          onChange={(e) => handleChange(e)}
          value={station.remarks || ""}
        />
      </div>

      {station?.stationPdfs?.[0]?.attachment === "" && (
        <div className="form-group col-md-6 flex">
          <div className="flex-1">
            <label htmlFor="remarks" className="col-form-label">
              Upload attachments:
            </label>
            <FileUploader
              id="file"
              name="stationPdfs"
              fileObjects={station?.stationPdfs}
              multipleUpload={true}
              setFieldValue={(key, value) => {
                setFieldValue(key, value);
              }}
            />
          </div>
        </div>
      )}

      {station?.stationPdfs?.[0]?.attachment !== "" && (
        <div className="col-md-6">
          <div className="flex-1">
            <label htmlFor="remarks" className="col-form-label">
              Uploaded attachments:
            </label>
            <div className="flex space-x-2">
              {station?.stationPdfs.map((pdf, index) => (
                <div className="flex-1" key={index}>
                  <ModalPdf contractPdf={pdf} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
