import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  updateStationMutation,
  addStationMutation,
  deleteStationMutation,
  CURRENT_USER_QUERY,
} from "../queries/queries";

import Pagination from "../components/Layout/Pagination";

import StationModal from "./../components/PageTransmitterSite/StationModal";
import Tooltip from "./../components/Layout/Tooltip";
import { gql } from "@apollo/client";
import CompaniesSelect from "../components/CompaniesSelect";
import LocationMap from "../components/PageTransmitterSite/LocationMap";

const getStationsQuery = gql`
  {
    stations {
      id
      description
      latitude
      longitude
      height
      remarks
      company {
        id
        name
      }
      site {
        id
        description
      }
      stationType {
        id
        name
        type
      }
      towerType {
        id
        name
      }
      sectors {
        id
        description
      }
      sketchfabModels {
        name
        modelUID
      }
      siteStatus
      siteIDUMTS
      siteIDLTE
      antennas {
        id
        code
        pix4dLink
        description
        company {
          name
        }
        frequency {
          name
          band {
            name
            service {
              id
              name
              technology {
                name
              }
            }
          }
        }
      }
      stationPdfs {
        id
        attachment
      }
    }
  }
`;

const initialValues = {
  id: "",
  description: "",
  latitude: "",
  longitude: "",
  height: "",
  remarks: "",
  companyId: "",
  siteId: "",
  towerTypeId: "",
  stationTypeId: "",
  siteStatus: "",
  siteIDUMTS: "",
  siteIDLTE: "",
  sectors: [{ description: "" }],
  stationPdfs: [{ attachment: "" }],
};

const StationsOverview = ({ user }) => {
  //MapStateVariables
  const [station, setStation] = useState(initialValues);
  const { loading, data } = useQuery(getStationsQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedCompany, setSelectedCompany] = useState(-1);

  //TableStateVariables
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    if (user.role.name === "OPERATOR" && selectedCompany !== user.u_company) {
      let role = user.role;
      setSelectedCompany(user.u_company);
    }
  });

  //TableStateFunctions
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let totalItems = 0;
  if (data && !loading) {
    totalItems = data.stations.length;
  }

  let filteredData = null;
  if (data && !loading) {
    if (parseInt(selectedCompany) === -1) {
      filteredData = data.stations;
    } else {
      filteredData = data?.stations.filter(
        (station) => station.company?.id === parseInt(selectedCompany)
      );
    }
  }

  const currentItems =
    !loading && filteredData
      ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
      : [];

  const [updateStation, { loading: updateLoading }] = useMutation(
    updateStationMutation,
    {
      variables: {
        id: station.id,
        description: station.description,
        latitude: parseFloat(station.latitude),
        longitude: parseFloat(station.longitude),
        height: parseInt(station.height),
        remarks: station.remarks,
        companyId: station.company ? parseInt(station.company.id) : 0,
        siteId: station.site ? parseInt(station.site.id) : 0,
        towerTypeId: station.towerType ? parseInt(station.towerType.id) : 0,
        stationTypeId: station.stationType
          ? parseInt(station.stationType.id)
          : 0,
        sector: station.sector,
        siteStatus: station.siteStatus,
        siteIDUMTS: station.siteIDUMTS,
        siteIDLTE: station.siteIDLTE,
        sectors: station.sectors,
        //stationPdfs: station.stationPdfs,
      },
      refetchQueries: [{ query: getStationsQuery }],
      awaitRefetchQueries: true,
    }
  );
  const [addStation, { loading: addLoading }] = useMutation(
    addStationMutation,
    {
      variables: {
        description: station.description,
        latitude: parseFloat(station.latitude),
        longitude: parseFloat(station.longitude),
        height: parseInt(station.height),
        remarks: station.remarks,
        companyId: station.company ? parseInt(station.company.id) : 0,
        siteId: station.site ? parseInt(station.site.id) : 0,
        towerTypeId: station.towerType ? parseInt(station.towerType.id) : 0,
        stationTypeId: station.stationType
          ? parseInt(station.stationType.id)
          : 0,
        sector: station.sector,
        siteStatus: station.siteStatus,
        siteIDUMTS: station.siteIDUMTS,
        siteIDLTE: station.siteIDLTE,
        sectors: station.sectors,
        stationPdfs: station.stationPdfs,
      },
      refetchQueries: [{ query: getStationsQuery }],
      awaitRefetchQueries: true,
    }
  );
  const [deleteStation] = useMutation(deleteStationMutation);

  const deleteItem = async (e, id) => {
    deleteStation({
      variables: {
        id,
      },
      refetchQueries: [{ query: getStationsQuery }],
      awaitRefetchQueries: true,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "company" ||
    name === "site" ||
    name === "stationType" ||
    name === "towerType"
      ? setStation({ ...station, [name]: { id: value } })
      : setStation({ ...station, [name]: value });
  };

  console.log(station);
  const handleSectorChange = (e, index) => {
    const { name, value } = e.target;
    const sectors = [...station.sectors];
    sectors[index][name] = value;
    setStation({ ...station, sectors });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const sectors = [...station.sectors];
    sectors.splice(index, 1);
    setStation({ ...station, sectors });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    const sectors = [...station.sectors];
    sectors.push({ description: "" });
    setStation({ ...station, sectors });
  };

  //ModalStatefunctions
  const openModal = (e, station) => {
    setStation(station);
    const modal = document.querySelector(e.target.dataset.target);

    //null check
    if (!modal) return;

    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };

  const saveItem = async () => {
    console.log(station);
    station.id ? updateStation() : addStation();
    closeModal();
  };

  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };

  return (
    <>
      {/* Stations Table */}
      <div className="card shadow mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Stations</h6>
          <div className="d-flex justify-content-center align-items-center">
            {/* Actions Col */}
            <div className="w-96 z-10">
              {user.role.name !== "OPERATOR" && (
                <CompaniesSelect setSelectedCompany={setSelectedCompany} />
              )}
            </div>
            {/* End Col */}
            {/* Actions Col */}
            {user.role.name !== "OPERATOR" && (
              <button
                className="btn btn-outline-primary my-2"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#stationModal"
                style={{ fontSize: ".9rem" }}
              >
                <i
                  className="fas fa-plus"
                  style={{ marginRight: "10px", fontSize: ".8rem" }}
                ></i>
                Add New Station
              </button>
            )}
            {/* End Col */}
          </div>
        </div>
        <div className="card-body">
          <div className="row ">
            <div className="col-sm-12 overflow-auto">
              <table className="table table-striped table-condensed table-hover table-sm">
                <thead>
                  <tr className="bg-primary text-white">
                    <td>
                      <Tooltip text="Tower or Location">Facility Name</Tooltip>
                    </td>
                    <td>Latitude</td>
                    <td>Longitude</td>
                    <td>Height above sea level</td>
                    <td>Company</td>
                    <td>Facility</td>
                    <td>Site</td>
                    <td>Tower Type</td>
                    <td>Remarks</td>
                    <td>sector</td>
                    <td>site status</td>
                    <td>site Id UMTS</td>
                    <td>site Id LTE</td>
                    <td>
                      <span style={{ float: "right" }}>actions</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="14">Loading...</td>
                    </tr>
                  ) : currentItems.length > 0 ? (
                    currentItems.map((station, index) => {
                      return (
                        <tr key={index}>
                          <td>{station.description}</td>
                          <td>{station.latitude}</td>
                          <td>{station.longitude}</td>
                          <td>{station.height}</td>
                          <td>{station.company?.name}</td>
                          <td>{station.stationType?.name}</td>
                          <td>{station.site?.description}</td>
                          <td>{station.towerType?.name}</td>
                          <td>{station.remarks}</td>
                          <td>
                            {station.sectors
                              ?.map((sector) => sector.description)
                              .join(",")}
                          </td>
                          <td>{station.siteStatus}</td>
                          <td>{station.siteIDUMTS}</td>
                          <td>{station.siteIDLTE} </td>
                          <td>
                            <div style={{ float: "right" }}>
                              <i
                                className="fas fa-fw fa-edit"
                                data-target="#stationModal"
                                onClick={(e) => openModal(e, station)}
                              ></i>
                              <i
                                className="fas fa-fw fa-trash"
                                onClick={(e) =>
                                  window.confirm(
                                    "Are you sure you wish to delete this item?"
                                  ) && deleteItem(e, station.id)
                                }
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="14" style={{ textAlign: "center" }}>
                        No Stations avaliable
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Map Locations Card */}
      <LocationMap stations={data?.stations} user={user} />
      <StationModal
        currentUserData
        station={station}
        handleChange={handleChange}
        handleSectorChange={handleSectorChange}
        handleRemoveClick={handleRemoveClick}
        handleAddClick={handleAddClick}
        closeModal={closeModal}
        saveItem={saveItem}
        setFieldValue={(name, value) => {
          setStation({
            ...station,
            [name]: value,
          });
        }}
      />
    </>
  );
};
export default StationsOverview;
